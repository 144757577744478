<template>
  <div class="problemReport pt-1 col-12">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <div>
        <div>
          <label>{{ $t('type') }}</label>
          <hr class="m-0 mb-2">
          <div
            class="m-radio-inline"
            style="display: flex; flex-direction:column;"
          >
            <label class="m-radio m-radio--state-primary">
              <input
                v-model="problemReport.severity"
                type="radio"
                name="noteRadioBoxes"
                value="8"
                class="pl-2"
              >{{ $t('troubleshootingQuestions') }}
              <span />
            </label>
            <label class="m-radio m-radio--state-primary">
              <input
                v-model="problemReport.severity"
                type="radio"
                name="noteRadioBoxes"
                value="2"
                class="pl-2"
              >{{ $t('information') }}
              <span />
            </label>
            <label class="m-radio m-radio--state-primary">
              <input
                v-model="problemReport.severity"
                type="radio"
                name="noteRadioBoxes"
                value="3"
                class="pl-2"
              >{{ $t('checkAgainTomorrow') }}
              <span />
            </label>
            <label class="m-radio m-radio--state-primary">
              <input
                v-model="problemReport.severity"
                type="radio"
                name="noteRadioBoxes"
                value="5"
                class="pl-2"
              >{{ $t('notifyOperations') }}<span />
            </label>
            <label
              v-if="authenticationHasRole('scope_customer')"
              class="m-radio m-radio--state-primary"
            >
              <input
                v-model="problemReport.severity"
                type="radio"
                name="noteRadioBoxes"
                value="-1"
                class="pl-2"
              >{{ 'Freshdesk Ticket' }}
              <span />
            </label>
          </div>
        </div>
      </div>
      <div
        v-if="problemReport.severity == 2 || problemReport.severity == 3 || problemReport.severity == 8"
        class="mt-4"
      >
        <label
          for="keycloakUsers"
          class="form-label"
        >User to notify</label>
        <LoadingPlaceholder v-if="userLoading" />
        <Multiselect
          v-else-if="keycloakUsers"
          id="keycloakUsers"
          v-model="selectedKeycloakUsers"
          tag-placeholder="Not found"
          placeholder="Select User"
          :options="keycloakUsers"
          :custom-label="customLabel"
          :multiple="true"
          :taggable="true"
          track-by="email"
          :hide-selected="true"
          :searchable="true"
          :close-on-select="true"
          class="mb-4"
        />
      </div>
      <div
        v-if="problemReport.severity != 8"
        class="mt-4"
      >
        <div class="">
          <label>{{ $t('installationDetailOperationView.note') }}</label>
          <textarea
            ref="messageArea"
            v-model="problemReport.text"
            :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Message') }]"
            name="Message"
            rows="4"
            placeholder="Please enter your note"
            @keydown.ctrl.enter="processNote()"
          />
          <span
            v-show="errors.has('Message')"
            class="badge badge-danger"
          >{{ errors.first('Message') }}</span>
        </div>
      </div>
      <div
        v-else
        class="mt-4"
      >
        <LoadingPlaceholder v-if="questionLoading" />
        <template v-else-if="questions">
          <form>
            <div
              class="form-group"
            >
              <label
                :for="`caller-q`"
                class="font-italic font-weight-bold"
              >Caller</label>
              <input
                :id="`caller-q`"
                v-model="callerOb"
                class="form-control"
              >
            </div>
            <div
              v-for="(question, index) in questions"
              :key="`question-${ index }`"
              class="form-group"
            >
              <label
                :for="`q-${index}`"
                class="font-italic font-weight-bold"
              >{{ question.question }}</label>
              <input
                :id="`q-${index}`"
                v-model="question.answer"
                class="form-control"
              >
            </div>
          </form>
        </template>
      </div>
      <div
        class="row mt-3"
      >
        <div
          v-if="problemReport.severity != 8"
          class="col-12"
        >
          <div class="cell">
            <h5>{{ 'Placeholders' }}</h5>
            <h6>{{ $t('common') }}</h6>
            <div class="row">
              <button
                class="btn btn-secondary"
                @click="addPlaceholder('{installation_name}')"
              >
                {{ $t('installationName') }}
              </button>
              <button
                class="btn btn-secondary ml-3"
                @click="addPlaceholder('{installation_url}')"
              >
                {{ 'Installation URL' }}
              </button>
              <button
                class="btn btn-secondary ml-3"
                @click="addPlaceholder('{current_date_time}')"
              >
                {{ $t('date') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="">
          <button
            class="btn btn-sm btn-primary float-right"
            @click.prevent="processNote()"
          >
            <font-awesome-icon
              class="mr-1"
              icon="pen"
            />
            <span>
              {{ $t('create') }}
            </span>
          </button>
          <div class="clearfix" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import problemReportAdapter from '@/adapter/problemReportAdapter.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "InstallationNote",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    problemReportAdapter,
    errorMixin,
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    caller: {
      type: String,
      required: false,
      default: null
    },
    replyRecipient: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      userLoading: true,
      keycloakUsers: null,
      selectedKeycloakUsers: null,
      questions: null,
      questionLoading: null,
      callerOb: null,
      problemReport: {
        product: 0,
        severity: 2,
        text: "",
        user: ""
      }
    }
  },
  watch: {
    "problemReport.severity" () {
      if(this.problemReport.severity == 8 && !this.questions) {
        this.getInstallation();
      }
    },
    "$i18n.locale" () {
      this.getInstallation();
    },
  },
  created () {
    if(this.caller) {
      this.callerOb = this.caller;
      this.problemReport.severity = 8
    }
    this.getUsers();
    this.loading = false;
  },
  methods: {
    customLabel ({ name, email }) {
      if(name) {
        return name;
      }
      if(email) {
        return email;
      }
      return "";
    },
    addPlaceholder (val) {
      if (this.problemReport.text == null) {
          this.problemReport.text = "";
        }
        var messageArea = this.$refs.messageArea;
        var messagePosition = messageArea.selectionStart
        var msg = this.problemReport.text;
        this.problemReport.text = msg.substring(0, messagePosition) + val + msg.substring(messagePosition);
        messageArea.focus();
    },
    processNote () {
      let text;
      if(this.problemReport.severity == 8) {
        text = "Caller: " + this.callerOb + " \n ------------------ \n";
        this.questions.forEach(q => {
          text += "<i><b>" + q.question + "</b></i>\n";
          text += q.answer + "\n";
          text += " \n";
        });
      }
      else {
        text = this.problemReport.text;
      }

      let zabbixRequest = this.createNote(this.installationId, text, this.problemReport.severity, this.selectedKeycloakUsers);

      zabbixRequest.then(() => {
        this.$snotify.success("The note successfully created.");
        this.problemReport.severity = 2;
        this.problemReport.text = "";
        this.$eventBus.$emit('refreshHistory');
        this.$emit("close");
        this.error_clear();
        this.loading = false;
      })
      .catch((error) => {
        this.error_clear();
        this.error_validate(error);
        this.loading = false;
      });
    },
    getUsers () {
      this.userLoading = true;
      this.axios.get('/User/GetKeycloakUsers')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.keycloakUsers = response.data;
          if(this.replyRecipient) {
            this.selectedKeycloakUsers = this.keycloakUsers.filter(x => x.name == this.replyRecipient);
          }
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    getInstallation () {
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          let installation = response.data;
          this.getQuestions(installation.installationTypeId)
        });
    },
    getQuestions (typeId) {
      this.questionLoading = true;
      this.axios.get(`/Installation/GetQuestionsByType?typeId=${ typeId }&locale=${this.$i18n.locale}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.questions = response.data;
        })
        .finally(() => {
          this.questionLoading = false;
        });
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
label:focus-within {
  outline: 1px solid lightblue;
}
</style>